import React, { useEffect } from "react";
import Layout from "../../components/Layout";
// @ts-ignore
import { graphql, PageProps, withPrefix } from "gatsby";
import SEO from "../../components/Seo";
import Helmet from "react-helmet";
import { LocalisedPageContext } from "../../types/PageTypes";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import { getLocalizedPath } from "../../utils/gatsbyBuild";
import {
  ImageInterface,
  LandingSectionsInterface,
  LocalizedContextInterface,
  SanityColorList,
  Seo
} from "../../types/SanityTypes";
import HeroBanner from "../../components/HeroBanner";
import BlockRenderer from "../../components/BlockRenderer";
import { event59 } from "../../analytics/event59";

type AdvancedTextPageQueryProps = {
  advancedTextPage: {
    _id: string;
    name: string;
    isBannerDisable: boolean;
    banner: {
      heading: string;
      subHeading?: string;
      textHighlight?: string;
      contentAlignment: string;
      _rawHeroImage: ImageInterface;
      _rawHeroVideo: any;
      primaryColor: SanityColorList;
      isVideoBanner: boolean;
    };
    seo: Seo;
    slug: {
      current: string;
    };
    parentSection?: {
      name: string;
      slug: {
        current: string;
      };
    };
    landingSections: LandingSectionsInterface[];
  };
} & LocalizedContextInterface;

type AdvancedTextPageContext = LocalisedPageContext;

export const query = graphql`
  query ($_id: String, $language: String) {
    advancedTextPage: sanityAdvancedTextPage(_id: { eq: $_id }) {
      ...AdvancedTextPageFieldsFull
      landingPageVariation
    }
    ...LocalizedContext
  }
`;

const AdvancedTextPage = (props: PageProps<AdvancedTextPageQueryProps, AdvancedTextPageContext>) => {
  const language = props.pageContext.language;
  const advancedTextPage = props.data.advancedTextPage;

  const { htmlLang, siteUrl } = useSiteMetadata(language);

  const pageTitle = advancedTextPage.banner.heading;
  const localizedPath = withPrefix(getLocalizedPath(advancedTextPage.slug?.current, advancedTextPage._id));

  useEffect(() => {
    event59(advancedTextPage.banner.heading);
  }, [advancedTextPage]);

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"advancedTextPage"}
      analyticsTagMeta={{ promoId: "1" }}
    >
      <StructuredData
        type={"AdvancedTextPage"}
        data={advancedTextPage.banner._rawHeroImage}
        language={htmlLang}
        slug={`/${advancedTextPage.slug.current}/`}
      />
      <Helmet>
        <link rel="canonical" href={`${siteUrl}${localizedPath}`} />
      </Helmet>
      <SEO
        title={advancedTextPage.seo.metaTitle}
        description={advancedTextPage.seo.metaDescription}
        imageUrl={advancedTextPage?.banner?._rawHeroImage?.asset.url}
        imageAlt={advancedTextPage?.banner?._rawHeroImage?.alt}
      />
      <div className="page_advanced-text-page-us">
        {!advancedTextPage.isBannerDisable ? (
          <HeroBanner
            sanityImage={advancedTextPage?.banner?._rawHeroImage}
            title={advancedTextPage.banner.heading}
            textHighlight={advancedTextPage.banner.textHighlight}
            subHeading={advancedTextPage.banner.subHeading}
            alignment={advancedTextPage.banner.contentAlignment}
            isVideoBanner={advancedTextPage.banner.isVideoBanner}
            video={advancedTextPage.banner._rawHeroVideo}
            landingPageVariation={advancedTextPage.landingPageVariation}
          />
        ) : null}
        <section data-testid="advanced-text-page-us">
          {/* 
            TODO:  Components be move out once static prototype is ready
          */}
          {advancedTextPage.landingSections &&
            advancedTextPage.landingSections.map((section: any, index: number) => (
              <BlockRenderer key={"landingSection" + index} section={section} />
            ))}
        </section>
      </div>
    </Layout>
  );
};

export default AdvancedTextPage;
