import { EventType, pushEvent } from "./index";

/**
 * Article View
 */

export const event59 = (title?: string) => {
  // @ts-ignore
  window.digitalData.newsletterSignUp = [];
  // @ts-ignore
  window.digitalData.newsletterSignUp.push({
    componentInfo: {
      componentID: "NewsletterSignUpForm"
    }
  });
  pushEvent({
    eventInfo: {
      type: EventType.trackEvent,
      eventAction: EventType.articleView,
      eventLabel: `Event Label: ${title}`,
      eventValue: 1
    },
    category: {
      primaryCategory: EventType.other
    },
    subcategory: "Read"
  });
};
